@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root{
    font-family: 'Montserrat', sans-serif;
}

body{
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: #fff;
}

.leading-kobi{
    line-height: 1.1;
}

.absolute-center{
    left: 50%;
    transform: translate(-50%, -50%);
}

::-webkit-scrollbar {
    display: none;
}

.dotted-bg{
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23000000' fill-opacity='0.06'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

* {
    margin: 0;
    padding: 0;
}

/* Icon 1 */

#nav-icon4 {
    width: 1.5rem;
    height: 1rem;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#nav-icon4 span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: rgb(232, 32, 32);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

#nav-icon4 span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
    top: 6px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
    top: 12px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -5px;
    left: 6px;
}

#nav-icon4.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 12px;
    left: 6px;
}

.nav-active{
    animation: slide-in-top 0.5s;
}

.nav-notactive{
    opacity: 0;
    animation: slide-out-top 1s;
}

.text {
    background-color: transparent;
    color: black;
    mix-blend-mode: exclusion;
}

@keyframes slide-in-top {
    0% {
        transform: translateY(-1000px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-out-top {
    0% {
        transform: translateY(0);
        height: 100vh;
        width: 100vw;
        opacity: 1;
    }

    100% {
        transform: translateY(-1000px);
        height: 0vh;
        opacity: 0;
    }
}
